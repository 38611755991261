import Vue from "vue";
import axios from "axios";

const guest = axios.create({
   //--------offline-------------
   //baseURL: "http://localhost:8085/",
//--------online-------------
  baseURL: "https://immo.azotche.com",

  headers: {
    "Content-Type": "multipart/form-data/application/json",
    //"Authorization": 'Bearer '+ localStorage.getItem('token')
  },
});

Vue.prototype.$guest = guest;

export { guest };
