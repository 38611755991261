import Vue from 'vue'
import Vuex from 'vuex'
import router from './router'
Vue.use(Vuex)

const state = {
  sidebarShow: 'responsive',
  sidebarMinimize: false,
  isLoggedIn: false,
  user: localStorage.getItem("user")
}

const mutations = {
  toggleSidebarDesktop (state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile (state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  
  set (state, [variable, value]) {
    state[variable] = value
  },
  login(state){
    state.isLoggedIn = true
   // router.push('/');
},
logout(state){
    state.isLoggedIn = false
    localStorage.removeItem("user");
    localStorage.removeItem("userId");
    localStorage.removeItem("c_hash_token");
    localStorage.removeItem("hash_token");
    localStorage.removeItem("c_hash");
    localStorage.removeItem("phone");
    localStorage.removeItem("country");
    localStorage.removeItem("idb");
    localStorage.removeItem("id_bailleur");

    router.push("/pages/connexion");
},
}

export default new Vuex.Store({
    state,
    getters:{
        loginState: (state) =>{
            return state.isLoggedIn
        },
        loginUser: (state) =>{
            return state.user
        }
    },
    mutations,
    actions:{
        login({commit}){
            commit("login");
        },
        logout({commit}){
            commit('logout')
        }
    },
});
  