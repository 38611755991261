import Vue from 'vue'
import Router from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const Dashboard = () => import('@/views/Dashboard')

// Views - Components

  //  Annonces
const AddAn = () => import('@/views/annonces/AddAn')
const ListAn = () => import('@/views/annonces/ListAn')
const UpdateAn = () => import('@/views/annonces/UpdateAn')
const VueAn = () => import('@/views/annonces/VueAn')

  //  Publications
const AddPub = () => import('@/views/publications/AddPub')
const ListPub = () => import('@/views/publications/ListPub')
const UpdatePub = () => import('@/views/publications/UpdatePub')
//const VuePub = () => import('@/views/publications/VuePub')

  //  Réservations

const ListRev = () => import('@/views/reservations/ListRev')

  // Visites
const ListVist = () => import('@/views/visites/ListVist')


  //   Faq
const AddFaq = () => import('@/views/faq/AddFaq')
const ListFaq = () => import('@/views/faq/ListFaq')
const UpdateFaq = () => import('@/views/faq/UpdateFaq')

  //  Offres
const AddOf = () => import('@/views/offres/AddOf')
const Params = () => import('@/views/offres/Parametres')
const ListOf = () => import('@/views/offres/ListOf')
const Listab = () => import('@/views/offres/Listab')
const UpdateOf = () => import('@/views/offres/UpdateOf')

  //  Attributs
const AddAt = () => import('@/views/attributs/AddAt')
const ListAt = () => import('@/views/attributs/ListAt')
const UpdateAt = () => import('@/views/attributs/UpdateAt')

  //  Bailleurs
const AddBail = () => import('@/views/bailleur/AddBail')
const ListBail = () => import('@/views/bailleur/ListBail')
const Aspirant = () => import('@/views/bailleur/Aspirant')
const UpdateBail = () => import('@/views/bailleur/UpdateBail')
const VueBail = () => import('@/views/bailleur/VueBail')

  //  Categories
const AddCat = () => import('@/views/categories/AddCat')
const ListCat = () => import('@/views/categories/ListCat')
const UpdateCat = () => import('@/views/categories/UpdateCat')
const VueCat = () => import('@/views/categories/VueCat')

  //  Newsletters
const AddNews = () => import('@/views/newsletters/AddNews')
const ListNews = () => import('@/views/newsletters/ListNews')
const UpdateNews = () => import('@/views/newsletters/UpdateNews')
const ShareNews = () => import('@/views/newsletters/ShareNews')
const NewsUsers = () => import('@/views/newsletters/NewsUsers')

  //  Devis
const AddDv = () => import('@/views/devis/AddDv')
const ListDv = () => import('@/views/devis/ListDv')
const UpdateDv = () => import('@/views/devis/UpdateDv')

  //  Utilisateurs
const ListUs = () => import('@/views/utilisateurs/ListUs')
const AddUs = () => import('@/views/utilisateurs/AddUs')
const UpdateUs = () => import('@/views/utilisateurs/UpdateUs')


// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/Login')
const Profil = () => import('@/views/pages/Profil')
const Register = () => import('@/views/pages/Register')
const Activate = () => import('@/views/pages/Activate')
const Recuperation = () => import('@/views/pages/Recuperation')
const Confirmation = () => import('@/views/pages/Confirmation')
const Rpassword = () => import('@/views/pages/Reset-password')

// Users
const Users = () => import('@/views/users/Users')
const User = () => import('@/views/users/User')

const hash = localStorage.getItem('c_hash_token');

Vue.use(Router)

export default new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes () {
  
  if(hash){
    return [
      {
        path: '/',
        redirect: '/dashboard',
        name: '',
        component: TheContainer,
        children: [
          {
            path: 'dashboard',
            name: 'Dashboard',
            component: Dashboard
          },              
          {
            path: 'users',
            meta: {
              label: 'Users'
            },
            component: {
              render(c) {
                return c('router-view')
              }
            },
            children: [
              {
                path: '',
                name: 'Users',
                component: Users
              },
              {
                path: ':id',
                meta: {
                  label: 'User Details'
                },
                name: 'User',
                component: User
              }
            ]
          },
          {
            path: 'annonces',
            
            meta: {
              label: 'Annonces'
            },
            component: {
              render (c) { return c('router-view') }
            },
            children: [
              {
                path: 'nouvelle',
                name: 'Nouvelle',
                component: AddAn
              },
              {
                path: '',
                name: 'Liste',
                component: ListAn
              },
              {
                path: 'edition',
                name: 'Edition',
                component: UpdateAn
              },
              {
                path: 'vue',
                name: 'Vue',
                component: VueAn
              },
            ]
          },   
          {
            path: 'publications',
            
            meta: {
              label: 'Publications'
            },
            component: {
              render (c) { return c('router-view') }
            },
            children: [
              {
                path: 'nouvelle',
                name: 'Nouvelle',
                component: AddPub
              },
              {
                path: '',
                name: 'Liste',
                component: ListPub
              },
              {
                path: 'edition',
                name: 'Edition',
                component: UpdatePub
              },
             /* {
                path: 'vue',
                name: 'Vue',
                component: VuePub
              },*/
            ]
          },            
          {
            path: 'faq',
            meta: {
              label: 'Faq'
            },
            component: {
              render (c) { return c('router-view') }
            },
            children: [
              {
                path: 'nouveau',
                name: 'Nouveau',
                component: AddFaq
              },
              {
                path: '',
                name: 'Liste',
                component: ListFaq
              },
              {
                path: 'edition',
                name: 'Edition',
                component: UpdateFaq
              },
            ]
          },
          {
            path: 'offres',
            meta: {
              label: 'Offres'
            },
            component: {
              render (c) { return c('router-view') }
            },
            children: [
              {
                path: 'nouveau',
                name: 'Nouvelle',
                component: AddOf
              },
              {
                path: '',
                name: 'Liste',
                component: ListOf
              },
              {
                path: 'parametres',
                name: 'Parametres',
                component: Params
              },
              {
                path: 'list-abonnees',
                name: 'Listab',
                component: Listab
              },
              {
                path: 'edition',
                name: 'Edition',
                component: UpdateOf
              },
            ]
          },
          {
            path: 'reservations',
            meta: {
              label: 'Réservations'
            },
            component: {
              render (c) { return c('router-view') }
            },
            children: [
             
              {
                path: '',
                name: 'Liste',
                component: ListRev
              },
            ]
          },
          {
            path: 'visites',
            meta: {
              label: 'Visites'
            },
            component: {
              render (c) { return c('router-view') }
            },
            children: [
             
              {
                path: '',
                name: 'Liste',
                component: ListVist
              },
            ]
          },
          {
            path: 'devis',
            meta: {
              label: 'Devis'
            },
            component: {
              render (c) { return c('router-view') }
            },
            children: [            
              {
                path: 'nouveau',
                name: 'AddDv',
                component: AddDv
              },
              {
                path: '',
                name: 'ListDv',
                component: ListDv
              },
              {
                path: 'updateDv',
                name: 'UpdateDv',
                component: UpdateDv
              },
            ]
          },
          
          {
            path: 'attributs',
            meta: {
              label: 'Attributs'
            },
            component: {
              render (c) { return c('router-view') }
            },
            children: [
              {
                path: 'nouveau',
                name: 'Nouveau',
                component: AddAt
              },
              {
                path: '',
                name: 'Liste',
                component: ListAt
              },
              {
                path: 'edition',
                name: 'Edition',
                component: UpdateAt
              },
            ]
          },
  
          {
            path: 'bailleur',
            meta: {
              label: 'Bailleur'
            },
            component: {
              render (c) { return c('router-view') }
            },
            children: [
              {
                path: 'aspirant',
                name: 'Aspirant',
                component: Aspirant
              },
              {
                path: 'nouveau',
                name: 'Nouveau',
                component: AddBail
              },
              {
                path: '',
                name: 'Liste',
                component: ListBail
              },
              {
                path: 'edition',
                name: 'Edition',
                component: UpdateBail
              },
              {
                path: 'vue',
                name: 'Vue',
                component: VueBail
              },
            ]
          },
  
          {
            path: 'categories',
            meta: {
              label: 'Categories'
            },
            component: {
              render (c) { return c('router-view') }
            },
            children: [
              {
                path: 'nouvelle',
                name: 'Nouvelle',
                component: AddCat
              },
              {
                path: '',
                name: 'Liste',
                component: ListCat
              },
              {
                path: 'edition',
                name: 'Edition',
                component: UpdateCat
              },
              {
                path: 'vue',
                name: 'Vue',
                component: VueCat
              },
            ]
          },
          {
            path: 'utilisateurs',
            meta: {
              label: 'Utilisateurs'
            },
            component: {
              render (c) { return c('router-view') }
            },
            children: [            
              {
                path: 'nouveau',
                name: 'Nouveau',
                component: AddUs
              },
              {
                path: '',
                name: 'Liste',
                component: ListUs
              },
              {
                path: 'edition',
                name: 'Edition',
                component: UpdateUs
              },
            ]
          },
          {
            path: 'newsletters',
            meta: {
              label: 'Newsletters'
            },
            component: {
              render (c) { return c('router-view') }
            },
            children: [           
              {
                path: 'nouveau',
                name: 'Nouveau',
                component: AddNews
              },
              {
                path: '',
                name: 'Liste',
                component: ListNews
              },
              {
                path: 'partage',
                name: 'Partage',
                component: ShareNews
              },
              {
                path: 'inscrits',
                name: 'Inscrits',
                component: NewsUsers
              },            
              {
                path: 'edition',
                name: 'Edition',
                component: UpdateNews
              },  
            ]
          },
         
        ]
      },
      {
        path: '/pages',
        redirect: '/pages/404',
        name: 'Pages',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: '404',
            name: 'Page404',
            component: Page404
          },
          {
            path: '500',
            name: 'Page500',
            component: Page500
          },
          {
            path: 'connexion',
            name: 'Connexion',
            component: Login
          },
          {
            path: 'profil',
            name: 'Profil',
            component: Profil
          },
          {
            path: 'inscription',
            name: 'Inscription',
            component: Register
          },
          {
            path: 'activate',
            name: 'Activate',
            component: Activate
          },
          {
            path: 'recuperation',
            name: 'Recuperation',
            component: Recuperation
          },
          {
            path: 'confirmation',
            name: 'Confirmation',
            component: Confirmation
          },
          {
            path: 'reset-password',
            name: 'reset-password',
            component: Rpassword
          },
        ]
      }
    ]

  }
  else{
    return [
      {
        path: '/',
        redirect: '/pages/connexion',
        name: '',
        component: TheContainer,
        children: [
          {
            path: 'dashboard',
            name: 'Dashboard',
            component: Dashboard
          },
                      
          {
            path: 'users',
            meta: {
              label: 'Users'
            },
            component: {
              render(c) {
                return c('router-view')
              }
            },
            children: [
              {
                path: '',
                name: 'Users',
                component: Users
              },
              {
                path: ':id',
                meta: {
                  label: 'User Details'
                },
                name: 'User',
                component: User
              }
            ]
          },
          {
            path: 'annonces',
            
            meta: {
              label: 'Annonces'
            },
            component: {
              render (c) { return c('router-view') }
            },
            children: [
              {
                path: 'nouvelle',
                name: 'Nouvelle',
                component: AddAn
              },
              {
                path: '',
                name: 'Liste',
                component: ListAn
              },
              {
                path: 'edition',
                name: 'Edition',
                component: UpdateAn
              },
              {
                path: 'vue',
                name: 'Vue',
                component: VueAn
              },
            ]
          },            
          {
            path: 'faq',
            meta: {
              label: 'Faq'
            },
            component: {
              render (c) { return c('router-view') }
            },
            children: [
              {
                path: 'nouveau',
                name: 'Nouveau',
                component: AddFaq
              },
              {
                path: '',
                name: 'Liste',
                component: ListFaq
              },
              {
                path: 'edition',
                name: 'Edition',
                component: UpdateFaq
              },
            ]
          },
          {
            path: 'offres',
            meta: {
              label: 'Offres'
            },
            component: {
              render (c) { return c('router-view') }
            },
            children: [
              {
                path: 'nouveau',
                name: 'Nouvelle',
                component: AddOf
              },
              {
                path: '',
                name: 'Liste',
                component: ListOf
              },
              {
                path: 'parametres',
                name: 'Parametres',
                component: Params
              },
              {
                path: 'list-abonnees',
                name: 'Listab',
                component: Listab
              },
              {
                path: 'edition',
                name: 'Edition',
                component: UpdateOf
              },
            ]
          },
          {
            path: 'devis',
            meta: {
              label: 'Devis'
            },
            component: {
              render (c) { return c('router-view') }
            },
            children: [            
              {
                path: 'nouveau',
                name: 'AddDv',
                component: AddDv
              },
              {
                path: '',
                name: 'ListDv',
                component: ListDv
              },
              {
                path: 'updateDv',
                name: 'UpdateDv',
                component: UpdateDv
              },
            ]
          },
          
          {
            path: 'attributs',
            meta: {
              label: 'Attributs'
            },
            component: {
              render (c) { return c('router-view') }
            },
            children: [
              {
                path: 'nouveau',
                name: 'Nouveau',
                component: AddAt
              },
              {
                path: '',
                name: 'Liste',
                component: ListAt
              },
              {
                path: 'edition',
                name: 'Edition',
                component: UpdateAt
              },
            ]
          },
  
          {
            path: 'bailleur',
            meta: {
              label: 'Bailleur'
            },
            component: {
              render (c) { return c('router-view') }
            },
            children: [
              {
                path: 'aspirant',
                name: 'Aspirant',
                component: Aspirant
              },
              {
                path: '',
                name: 'Liste',
                component: ListBail
              },
              {
                path: 'edition',
                name: 'Edition',
                component: UpdateBail
              },
              {
                path: 'vue',
                name: 'Vue',
                component: VueBail
              },
            ]
          },
  
          {
            path: 'categories',
            meta: {
              label: 'Categories'
            },
            component: {
              render (c) { return c('router-view') }
            },
            children: [
              {
                path: 'nouvelle',
                name: 'Nouvelle',
                component: AddCat
              },
              {
                path: '',
                name: 'Liste',
                component: ListCat
              },
              {
                path: 'edition',
                name: 'Edition',
                component: UpdateCat
              },
              {
                path: 'vue',
                name: 'Vue',
                component: VueCat
              },
            ]
          },
          {
            path: 'utilisateurs',
            meta: {
              label: 'Utilisateurs'
            },
            component: {
              render (c) { return c('router-view') }
            },
            children: [            
              {
                path: 'nouveau',
                name: 'Nouveau',
                component: AddUs
              },
              {
                path: '',
                name: 'Liste',
                component: ListUs
              },
              {
                path: 'edition',
                name: 'Edition',
                component: UpdateUs
              },
            ]
          },
          {
            path: 'newsletters',
            meta: {
              label: 'Newsletters'
            },
            component: {
              render (c) { return c('router-view') }
            },
            children: [           
              {
                path: 'nouveau',
                name: 'Nouveau',
                component: AddNews
              },
              {
                path: '',
                name: 'Liste',
                component: ListNews
              },
              {
                path: 'partage',
                name: 'Partage',
                component: ShareNews
              },
              {
                path: 'inscrits',
                name: 'Inscrits',
                component: NewsUsers
              },            
              {
                path: 'edition',
                name: 'Edition',
                component: UpdateNews
              },  
            ]
          },
        ]
      },
      {
        path: '/pages',
        redirect: '/pages/404',
        name: 'Pages',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: '404',
            name: 'Page404',
            component: Page404
          },
          {
            path: '500',
            name: 'Page500',
            component: Page500
          },
          {
            path: 'connexion',
            name: 'Connexion',
            component: Login
          },
          {
            path: 'profil',
            name: 'Profil',
            component: Profil
          },
          {
            path: 'inscription',
            name: 'Inscription',
            component: Register
          },
          {
            path: 'activate',
            name: 'Activate',
            component: Activate
          },
          {
            path: 'recuperation',
            name: 'Recuperation',
            component: Recuperation
          },
          {
            path: 'confirmation',
            name: 'Confirmation',
            component: Confirmation
          },
          {
            path: 'reset-password',
            name: 'reset-password',
            component: Rpassword
          },
        ]
      }
    ]

  }
}

