import 'core-js/stable'
import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { BootstrapVue } from 'bootstrap-vue'
import { guest } from './plugins/axios.js';
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import { LMap, LTileLayer, LMarker, LPopup } from 'vue2-leaflet';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import Moment from 'moment';
Moment.locale( 'fr' );
Object.defineProperty( Vue.prototype, '$moment', { value: Moment });
import 'leaflet/dist/leaflet.css';
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
   iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
   iconUrl: require('leaflet/dist/images/marker-icon.png'),
   shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});
Vue.component('v-select', vSelect);
Vue.component('l-popup', LPopup);
Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);

Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.prototype.$log = console.log.bind(console)
Vue.use(BootstrapVue)

new Vue({
  el: '#app',
  router,
  store,
  guest,
  icons,
  template: '<App/>',
  components: {
    App
  }
})
